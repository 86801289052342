<template>
    <v-form>
        <v-card>
            <v-card-title>
                <span class="headline">Add Test</span>
            </v-card-title>

            <v-card-text>
                <app-autocomplete
                    v-model="testGroupId"
                    label="Test Group"
                    :schema="getSchema('TestGroups')"
                    placeholder="Choose the type of test"
                    list-url="/api/TestGroups/List"
                    url="/api/TestGroups"
                    item-value="id"
                    item-text="name"
                    :filter="filter" />
                <v-expand-transition>
                    <v-container v-show="hasTestGroup">
                        <app-autocomplete
                            :key="testKey"
                            v-model="testId"
                            label="Test"
                            :schema="getSchema('Tests')"
                            placeholder="Choose the test you would like performed"
                            list-url="/api/Tests/List"
                            url="/api/Tests"
                            item-value="id"
                            item-text="name"
                            :filter="testFilter"
                            :required="true" />
                    </v-container>
                </v-expand-transition>
                <v-expand-transition>
                    <v-container v-show="hasTest">
                        <app-autocomplete
                            :key="labKey"
                            v-model="labId"
                            label="Lab"
                            :schema="getSchema('Labs')"
                            placeholder="Choose the lab you would like the test performed at"
                            list-url="/api/Labs/List"
                            url="/api/Labs"
                            item-value="id"
                            item-text="name"
                            :custom-filter="labFilter"
                            :required="true" />
                    </v-container>
                </v-expand-transition>
            </v-card-text>

            <v-card-actions>
                <div class="flex-grow-1" />
                <v-btn color="quaternary" text @click="$emit('cancel')">
                    Cancel
                </v-btn>
                <v-btn color="quaternary" text @click="add">
                    Add
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import axios from "axios"
import { or, equal } from "@/services/filtering"
export default {

    components: {
        appAutocomplete: () => import('@/components/AppAutocomplete')
    },
    props: {
        filter: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            testId: null,
            testGroupId: null,
            labId: null,
            testKey: 0,
            labKey: 0,
            labFilter: null
        }
    },

    computed: {
        testFilter() {
            if (this.isEmpty(this.testGroupId)) {
                return this.filter;
            }
            return {
                ...this.filter,
                testGroupId: this.testGroupId
            }
        },
        hasTestGroup() {
            return this.testGroupId !== null && typeof this.testGroupId !== "undefined"
        },
        hasTest() {
            return this.testId !== null && typeof this.testId !== "undefined"
        }
    },

    watch: {
        testGroupId() {
            this.testId = null;
            this.testKey++;
        },
        testId: {
            async handler() {
                this.labId = null;
                this.labKey++;
                if (this.isEmpty(this.testId)) {
                    return;
                }
                let params = {
                    filter: equal("testId", this.testId, "guid")
                };
                let response = await axios.post("/api/LabTests/List", params)
                let filters = response.data.items.map(e => equal("id", e.labId, "guid"));
                if (filters.length !== 0) {
                    this.labFilter = or(filters);
                }
            }
        }
    },

    methods: {
        add() {
            this.$emit('input', { testId: this.testId, labId: this.labId });
        }
    }
};
</script>
